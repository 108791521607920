export const PAGE_PERMISSION = {
    DASHBOARD: 1,
    RIDERS: 2,
    // HEAT_MAP:3,
    VEHICLES: 4,
    // ANALYTICS:5,
    DISPUTE: 6,
    // CONTACT:7,
    SERVICE_REQUEST: 7,
    NOTIFICATIONS: 8,
    ROLES: 9,
    RIDES: 10,

    SUPPORT: 12,
    COMMUNITY_MODE: 13,
    VEHICLE_REPORT: 14,

    USERS: 21,
    FRANCHISEE: 22,
    FEEDER: 23,
    PAYMENT: 33,

    MASTER: 41,
    DATABANK: 42,
    LOCATION: 43,

    RIDE_SETTING: 51,
    CANCELLATION_REASON: 52,
    STATIC_PAGE: 53,
    FARE_MANAGEMENT: 54,
    FAQS: 55,
    PROCEDURE: 56,
    PROMOTIONS: 57,
    // TASK: 58,
    FEEDBACK: 58,
    WALLET_CONFIG: 59,
    ACTION_QUESTIONNAIRE: 60,

    ZONES: 65,
    NEST: 66,
    VERSION: 67,
    TASKSETUP: 68,
    CREATE_TASK: 69,

    SUBSCRIPTION: 81,
    BOOKING_PASS: 82,
    RENTAL: 83,
    RENTAL_PAYMENT: 84,
    RENTAL_PAYMENT_CLIENT: 85,
    GENERAL_SETTINGS: 87,
    GEO_LOCATION: 88,

    ABOUT_US: 90,
    COMMISSION: 91,
    COMMISSION_PAYOUT: 92,
    PRIVACY_POLICY: 93,
    TERMS_AND_CONDITIONS: 94,
    COMMISSION_REPORT: 95,
    DEALER: 97,
    CONTACT_US: 99,
    
    OPERATIONAL_HOURS: 100,
    ACCOUNT_DELETE_REQUEST: 105
};
